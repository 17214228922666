import React from "react";
import logoImg from "../assets/img/logo_full.png";
import "@material/web/all"

const Home = () => {
    return (<div style={{padding: '16px'}}>
        <p className='title-large' style={{fontWeight: 'bold'}}>인사이츠랩 사이트</p>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '16px',
            marginBottom: '16px'
        }}>
            <img style={{
                borderRadius: '16px',
            }} width={256} src={logoImg} alt={'Insight LAB'}/>
        </div>
        <div style={{margin: '16px'}}>
            <p className="body-large">INSIGHTS LAB 사이트를 방문해 주신 모든 분을 환영합니다.<br/>
                저희 홈페이지는 INSIGHTS LAB에 관한 가장 기본적인 정보를 간략하게 제공하고 있습니다.<br/>
                여러분들과 함께 INSIGHTS LAB의 다양한 활동을 전개하여 더욱 풍성한 자료를 업데이트해 나가겠습니다.<br/>
                감사합니다. </p>
        </div>
    </div>);
}

export default Home;