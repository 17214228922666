import React, {useState} from "react";
import "@material/web/tabs/secondary-tab";
import researchImg from "../assets/img/research.webp";
import solutionImg from "../assets/img/solution.webp";
import consultingImg from "../assets/img/consulting.webp";

const Business = () => {
    const [filter, setFilter] = useState(1);

    const contentSwitch = (value) => {
        switch (value) {
            case 1:
                return (<div style={{padding: '16px'}}>
                    <div style={{width: '100%', height: '200px', overflow: 'hidden'}}>
                        <img style={{
                            borderRadius : '16px',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }} src={researchImg}
                             alt={'Insight LAB'}/>
                    </div>
                    <p className='title-large' style={{fontWeight:'bold'}}>인사이트 연구 개발 research & development</p>
                    <p className='body-large'><b>INSIGHTS LAB은</b> <br/>
                    데이터 분석, 여론 조사, 공공정책 개발, 정치(선거)·정책 컨설팅 등 다양한 분야에서 인사이트를 얻기 위한 연구 개발을 수행합니다.</p>
                </div>);
            case 2:
                return (<div style={{padding: '16px'}}>
                    <div style={{width: '100%', height: '200px', overflow: 'hidden'}}>
                        <img style={{
                            borderRadius : '16px',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }} src={solutionImg}
                             alt={'Insight LAB'}/>
                    </div>
                    <p className='title-large' style={{fontWeight: 'bold'}}>솔루션 업그레이드 solution upgrade</p>
                    <p className='body-large'><b>INSIGHTS LAB은</b> <br/>
                        특정 문제 해결을 위한 전략적인 접근 방식, 참신한 프로세스에 기반한 인사이트를 반영해 조사 분석 및 연구 결과를 재해석하여 솔루션을 업그레이드합니다.</p>
                </div>
            );
            case 3:
            return (<div style={{padding: '16px'}}>
                    <div style={{width: '100%', height: '200px', overflow: 'hidden'}}>
                        <img style={{
                            borderRadius: '16px',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover'
                        }} src={consultingImg}
                             alt={'Insight LAB'}/>
                    </div>
                <p className='title-large' style={{fontWeight: 'bold'}}>컨설팅 및 리포팅 consulting & reporting</p>
                <p className='body-large'><b>INSIGHTS LAB은</b><br/>
                    전략, 홍보, 정책, 정치(선거) 등 다양한 분야에서 수행된 각종 조사, 연구, 결정 등의 적실성을 제고하기 위한 인사이트를 비정기 또는 정기적으로 제시합니다.</p>
                </div>);
            default :
                return (<h2>insight Lab</h2>);
        }
    }

    return (
        <div style={{padding: '16px'}}>
            <p className='title-large' style={{fontWeight:'bold'}}>인사이츠랩 사업분야</p>
            <md-tabs>
                <md-secondary-tab onClick={() => setFilter(1)}>
                    연구 개발
                </md-secondary-tab>
                <md-secondary-tab onClick={() => setFilter(2)}>
                    솔루션 업그레이드
                </md-secondary-tab>
                <md-secondary-tab onClick={() => setFilter(3)}>
                    컨설팅 및 리포팅
                </md-secondary-tab>
            </md-tabs>
            {contentSwitch(filter)}
        </div>

    );
}

export default Business;