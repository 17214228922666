import React from "react";
import introduceImg from "../assets/img/introduce.webp";

const Introduce = () => {
    return (
        <div style={{padding: '16px'}}>
            <p className='title-large' style={{fontWeight: 'bold'}}>인사이츠랩 센터소개</p>
            <div style={{width: '100%', height: '280px', overflow: 'hidden', marginBottom:'24px'}}>
                <img style={{
                    borderRadius: '16px',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }} src={introduceImg}
                     alt={'Insight LAB'}/>
            </div>
            <div>
                <p className='body-large'><b>INSIGHTS LAB은 </b><br/>
                    * 정책 디자인 Policy Design<br/>
                    * 전략 플래닝 Strategic Planning<br/>
                    * 선거 캠페인 Election Campaign<br/>
                    * 홍보 마케팅 Promotional Marketing<br/>
                    등의 관련 프로젝트나 연구용역에서 인사이트를 필요로 하는 여러분들과 함께 하는<br/>
                    <b>네트워크형 랩Lab 센터</b>를 지향합니다.</p>
            </div>
        </div>
    )
        ;
}

export default Introduce;