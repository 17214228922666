import React from 'react';

const NotFound = () => {
    return (
        <div style={{padding: '16px'}}>
            404 Error
        </div>
    );
};

export default NotFound;