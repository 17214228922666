import React from "react";

const CopyRights = () => {
    return (<div style={{padding: '16px'}}>
        <p className='title-large' style={{fontWeight: 'bold'}}>인사이츠랩 사이트 저작권 안내</p>
        <p className='body-large'> * Logo 풀버전 글씨체 : Gmarket Sans</p>
        <p className='body-large'> * 사이트 내 폰트 : Pretendard</p>
        <p className='body-large'> * Logo 및 사이트 내 각종 이미지 : ChatGPT-4 활용</p>
    </div>);
}

export default CopyRights;