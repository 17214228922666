import React from 'react';
import './assets/css/baseline.css';
import LabRoutes from "./routes";
import {BrowserRouter} from 'react-router-dom';
import "@material/web/tabs/tabs";
import "@material/web/tabs/primary-tab";
import "@material/web/divider/divider";
import Header from "./component/header";
import Footer from "./component/footer";


const App = () => {
    return (
        <div>
            <BrowserRouter>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{
                        width: '100%',
                    }}>
                        <Header/>
                        <div style={{
                            width: '100%',
                            height: '720px',
                            border: `1px solid #DDDDDD`,
                        }}>
                            <LabRoutes/>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </BrowserRouter>
        </div>);
}

export default App;
