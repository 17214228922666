import React from "react";
import methodImg from "../assets/img/method.webp";

const Method = () => {
    return (<div style={{padding: '16px'}}>
        <p className={'title-large'} style={{fontWeight: 'bold'}}>인사이츠랩 수행방식</p>
        <div style={{width: '100%', height: '280px', overflow: 'hidden', marginBottom:'24px'}}>
            <img style={{
                borderRadius: '16px',
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }} src={methodImg}
                 alt={'Insight LAB'}/>
        </div>
        <p className={'body-large'}>
            <b>INSIGHTS LAB은</b><br/>
            단독으로 과제를 수행하지만, 클라이언트Client의 요청에 따라 프로젝트 공동수행co-working이나 아웃소싱 서비스outsourcing service를 제공합니다.<br/>
            <b>INSIGHTS LAB은</b><br/>
            필요할 경우 과제 특성에 따라 해당분야 전문가들을 초빙하여 TF팀을 구성해서 클라이언트의 니즈needs에 최대한 접근하고자 최선의 노력을 기울입니다.</p>

    </div>);
}

export default Method;