import React from "react";
import performanceImg from "../assets/img/performance.webp";

const Performance = () => {
    return (<div style={{padding: '16px'}}>
            <p className='title-large' style={{fontWeight: 'bold'}}>인사이츠랩 수행실적</p>
            <div style={{width: '100%', height: '280px', overflow: 'hidden', marginBottom:'24px'}}>
                <img style={{
                    borderRadius: '16px',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                }} src={performanceImg}
                     alt={'Insight LAB'}/>
            </div>
            <p className='body-large'>INSIGHTS LAB의 수행실적은 계속 추가하겠습니다.<br/>
                관심 가져 주셔서 감사합니다.</p>
        </div>
    );
}

export default Performance;