import React from 'react';
import '@material/web/all'
import {useNavigate} from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <div style={{
            padding: '16px',
            borderRadius: '0px 0px 20px 20px',
            backgroundColor: '#212677',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                marginRight: '16px',
            }}>
                <p className='label-large' style={{color: 'white'}}>
                    인사이츠랩 INSIGHTS LAB
                </p>
                <p className='label-medium' style={{color: "white", marginTop: '-8px'}}>
                    05071 서울특별시 광진구 동일로18길 11, 403호
                </p>
                <p className='label-small' style={{color: 'white', marginTop: '-8px'}}>
                    https://lab.hov.kr ｜E-mail: insights@lab.hov.kr
                </p>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginTop: '-16px'
            }}>
                <p className='label-small' style={{color: '#4555b0', cursor: 'pointer'}}
                   onClick={() => navigate("/copy-rights")}>ⓒHOV All Rights Reserved.</p>
            </div>
            </div>
        </div>
    );
}

export default Footer;