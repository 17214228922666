import React from 'react';
import {useNavigate} from "react-router-dom";
import logo from "../assets/img/logo.png";


const Header = () => {
    const navigate = useNavigate();
    return (
        <div style={{width: '100%', marginTop: '16px'}}>
            <md-tabs>
                <md-primary-tab onClick={() => navigate("/")}>
                    <div style={{ backgroundColor:'#ffffff', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
                        <img style={{width: '30px', height: '30px'}} src={logo}/>
                        <p style={{fontSize: '25px'}}>INSIGHTS LAB</p>
                    </div>
                </md-primary-tab>
                <md-primary-tab onClick={() => navigate("/introduce")}>소개
                </md-primary-tab>
                <md-primary-tab onClick={() => navigate("/business")}>사업분야
                </md-primary-tab>
                <md-primary-tab onClick={() => navigate("/method")}>수행방식
                </md-primary-tab>
                <md-primary-tab onClick={() => navigate("/performance")}>수행실적
                </md-primary-tab>
            </md-tabs>
        </div>
    );
}

export default Header;