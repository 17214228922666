import React from "react";
import {Route, Routes} from "react-router-dom";
import Introduce from "../introduce";
import Business from "../business";
import Method from "../method";
import Performance from "../performance";
import Home from "../home";
import NotFound from "../not-found/not-found";
import CopyRights from "../copy-rights";

const LabRoutes = () => {

    return (
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/introduce" element={<Introduce/>}/>
            <Route path="/business" element={<Business/>}/>
            <Route path="/method" element={<Method/>}/>
            <Route path="/performance" element={<Performance/>}/>
            <Route path="/copy-rights" element={<CopyRights />}></Route>
            <Route path="*" element={<NotFound />}></Route>
        </Routes>
    );
}

export default LabRoutes;